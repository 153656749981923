<template>
    <div class="background">
        <!-- Отрисовка выигрышного номера -->
        <img v-if="isZero"
             :src="win37HoverImage"
             :style="result37PositionsStyle"
             class="result__number"
             alt=""/>
        <img v-else
             :src="winNumberHoverImage"
             :style="resultPositionsStyle"
             class="result__number"
             alt=""/>

        <div v-for="(bet, index) in combineBetsAndWins"
             :key="index"
             :style="chipPositionsStyle(bet.code)"
             class="stake"
        >
            <live-roulette-chip :bet="bet.amount"
            />
        </div>
    </div>
</template>

<script>
import LiveRouletteChip from "./LiveRouletteChip.vue";

export default {
    components: {LiveRouletteChip},
    props: {
        roundData: {
            type: Object,
            required: true,
            default() {
                return {
                    resultNumber: undefined,
                }
            }
        },
        tickets: {
            type: Array,
            required: true,
            default() {
                return [{
                    bet: undefined,
                    win: undefined,
                    ticket_data: "",
                    player_id: undefined,
                }]
            }
        }
    },
    data() {
        return {
            cell: {
                width: 90,
                height: 114,
                bottomHeight: 72
            },
            chip: {
                size: 46
            }
        }
    },
    computed: {
        isZero() {
            return this.roundData.resultNumber === 0;
        },
        winNumberHoverImage() {
            return require('@/assets/games/realtime/liveroulette/win.png');
        },
        win37HoverImage() {
            return require('@/assets/games/realtime/liveroulette/win_37.png');
        },
        resultPositionsStyle() {
            return {
                left: `${this.resultLeft}px`,
                top: `${this.resultTop}px`,
            }
        },
        result37PositionsStyle() {
            return {
                left: '0px',
                top: '0px',
            }
        },
        resultLeft() {
            return (this.cell.width * 0.81 + Math.floor((this.roundData.resultNumber - 1) / 3) * this.cell.width);
        },
        resultTop() {
            return (this.cell.height * 2 - ((this.roundData.resultNumber - 1) % 3) * this.cell.height);
        },
        combineBetsAndWins() {
            const betsAndWins = [];

            const bets = this.tickets;

            for (let i = 0; i < bets.length; i++) {
                const data = JSON.parse(bets[i].ticket_data);
                data.stakes.forEach(stake => {
                    betsAndWins.push(stake);
                });
            }

            return betsAndWins;
        }
    },
    methods: {
        chipPositionsStyle(code) {
            let left = 0, top = 0;

            // Основные ставки
            if (code >= 1 && code <= 36) {
                left = this.cell.width / 2 + this.cell.width * Math.floor((code - 1) / 3);
                top = this.cell.height * 3 - this.cell.height / 2 - ((code - 1) % 3) * this.cell.height;
            }
            //нижний ряд
            if (code >= 52 && code <= 75) {
                left = (code - 52) * this.cell.width / 2;
                top = this.cell.height * 3;
            }
            //третий ряд снизу
            if (code >= 88 && code <= 111) {
                left = (code - 88) * this.cell.width / 2;
                top = this.cell.height * 2;
            }
            //пятый ряд снизу
            if (code >= 124 && code <= 147) {
                left = (code - 124) * this.cell.width / 2;
                top = this.cell.height;
            }

            //второй ряд снизу (сплиты  ряда от 1 до 34)
            if (code >= 76 && code <= 87) {
                left = (code - 76) * this.cell.width;
                top = this.cell.height * 2.5;
            }

            //четвертый ряд снизу (сплиты  ряда от 2 до 35)
            if (code >= 112 && code <= 123) {
                left = (code - 112) * this.cell.width;
                top = this.cell.height * 1.5;
            }

            //шестой ряд снизу (сплиты  ряда от 2 до 35)
            if (code >= 148 && code <= 159) {
                left = (code - 148) * this.cell.width;
                top = this.cell.height * 0.5;
            }

            //1st 12, 2nd 12, 3rd 12
            if (code >= 44 && code <= 46) {
                left = this.cell.width * 2 + (code - 44) * this.cell.width * 4;
                top = this.cell.height * 3 + this.cell.bottomHeight / 2;
            }

            //line of 2:1
            if (code >= 47 && code <= 49) {
                left = this.cell.width * 12.1 + this.cell.width / 3;
                top = this.cell.height * 0.5 + this.cell.height * (code - 47);
            }

            switch (Number(code)) {
                case 0: //37
                    left = this.cell.width * (-0.4);
                    top = this.cell.height * 1.5;
                    break;
                case 50: //1 to 18
                    left = this.cell.width;
                    top = this.cell.height * 3 + this.cell.bottomHeight * 1.5;
                    break;
                case 42: //Even
                    left = this.cell.width * 3;
                    top = this.cell.height * 3 + this.cell.bottomHeight * 1.5;
                    break;
                case 41: //Red
                    left = this.cell.width * 5;
                    top = this.cell.height * 3 + this.cell.bottomHeight * 1.5;
                    break;
                case 40: //Black
                    left = this.cell.width * 7;
                    top = this.cell.height * 3 + this.cell.bottomHeight * 1.5;
                    break;
                case 43: //Odd
                    left = this.cell.width * 9;
                    top = this.cell.height * 3 + this.cell.bottomHeight * 1.5;
                    break;
                case 51: //19 to 36
                    left = this.cell.width * 11;
                    top = this.cell.height * 3 + this.cell.bottomHeight * 1.5;
                    break;
            }

            return {
                left: `${left}px`,
                top: `${top}px`,
            }
        }
    },
    mounted() {
        this.$store.dispatch('workspace/setHeight', 488);
        this.$store.dispatch('workspace/setWidth', 1227);
    }
}
</script>

<style scoped>
.background {
    background-image: url('../../../../../assets/games/realtime/liveroulette/bg.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 1227px;
    height: 488px;
}

.result__number {
    position: absolute;
}

.stake {
    position: absolute;
    width: 46px;
    height: 46px;
    margin-top: -23px;
    margin-left: 51px;
}
</style>
